.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.json-formatted {
  background-color: blueviolet;
  color: #fcf9fb;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 0.9rem;
  overflow: auto;
}

.json-formatted.change-fade {
  background-color: #192348;
  animation-name: fade;
  animation-duration: 2s;
}

.mirror {
  display: inline-block;
  transform: scaleX(-1);
  filter: FlipH; /* IE 6-7-8 */
}

@keyframes fade {
  from {
    background-color: blueviolet;
  }
  to {
    background-color: #192348;
  }
}
